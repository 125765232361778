@import '../../Styles/Variabls';
@import '../../Styles/Mixin';

.comment-box {
    @include flex-box;
    padding: $padding-10;
    a {
       width: fit-content;
       min-width: fit-content;
      .auther-img {
         width: 40px;
         height: 40px;
         border-radius: $border-r-full;
      }  
    }
    .auther-desc {
      border-bottom: 1px solid transparent;
      padding-bottom: $padding-15;
       .auth-name {
            display: inline;
            font-size: $font-size-5;
         }    
       .time {
          font-size: $font-size-6;
          margin: 0 $margin-l;
       }
       .comment {
          font-size: $font-size-6;
          -ms-word-break: break-all;
          -moz-word-break:break-all;
          word-break: break-all;
          max-width: 100%;
       }
       .like-dislike-btn {
          margin-top: $margin-m;
          @include flex-box(center);
          button {
            padding: 0 $padding-10;
            font-size: $font-size-m;
            @include flex-box(center,center,$gap-m);
            border-radius: $border-r-10;
            background-color: transparent;
            svg {
               width: 17px;
            }
          }
          .replies {
            font-size: $font-size-6;
            font-weight: bold;
          }
       }
    }
}
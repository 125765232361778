@import '../../Styles/Variabls';
@import '../../Styles/Mixin';

.channel-about {
    @include flex-box(center,center,0);
     position: fixed;
     top: 0;
     left: 0;
     width: 100vw;
     height: 100vh;
     z-index: 999999999;
    .about-container {
        max-width: 550px;
        min-width: 350px;
        border-radius: $border-r-10;
        padding-bottom: $padding-15;
        h3 {
            font-weight: 700;
            margin: $marging-15 0;
            text-transform: capitalize;
        }
        .about-nav {
            @include flex-box(center,space-between);
            padding: 0 $padding-l + 10;
        }
        .channel-desc {
            max-height: calc(100vh - 150px);
            overflow-y: scroll;
            padding: 0 $padding-20;
            .describtion {
                font-size: $font-size-6;
                font-weight: 500;
            }
            .channel-links {
                .link {
                    @include flex-box(center);
                    &:not(:last-of-type){
                        margin-bottom: $marging-15;
                    }
                    .icon {
                        min-width: 30px;
                        img {
                            width: 30px;
                            height: 30px;
                            border-radius: $border-r-full;
                        }
                    }
                }
            }
            .channel-details {
                .details {
                    li {
                       @include flex-box(center);
                        &:not(:last-of-type){
                            margin-bottom: $marging-15;
                        }
                    }
                }
            }
        }
    }
}
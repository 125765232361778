
.search-feed {
    max-width:100%;
    margin: 0 auto;
    display: flex;
    gap: 10px;
    .container {
        padding: 0 15px;
        max-width: 100%;
        @media (min-width: 790px) {
            max-width: calc(100% - 130px); 
            .video-card {

                .video-desc {
                    display: block !important;
                }
            }
            .first-tow-video {
                display: block !important;
            }
        }
    }

}
@import '../../../../../Styles/Variabls';
@import '../../../../../Styles/Mixin';

.ch-home-player {
    max-width: 100%;
    padding: $padding-15 0;
    height: fit-content !important;
    overflow: visible !important;
    min-width: 100%;
    .player {
      border-radius: $border-r-10;
      overflow: hidden;
      min-width: 100%;
      height: 240px !important;
      min-height: 222px !important;
      background-color: black;

      @media (min-width: 992px) {
        flex-basis: 40% !important;
      }
    }
    .player-desc {
        @media (min-width: 992px) {
            flex-basis: 60% !important;
          }
        .pl-title {
            margin: $marging-10 0;
            font-size: $font-size-5;
            max-height: 21px;
            overflow: hidden;
        }
        .time-views {
            span {
                font-size: $font-size-6;
            }
        }
        aside {
            font-size: $font-size-6;
            font-weight: 500;
            max-height: 160px;
            overflow: hidden;
            display: none;
        }
    }
    @media (min-width: 570px) {
        @include flex-box(_,_,$gap-10);
        .player {
            flex-basis: 50%;
            min-width: unset;
        }
        .time-views {
            margin: $marging-10 0;
        }
        .player-desc {
            flex-basis: 50%;
            .pl-title {
                margin: 0;
            }
            aside {
                display: block;
            }
        }
    }
}
@import "../../Styles/Variabls";
@import "../../Styles/Mixin";
.height {
  height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
};

.shorts-ul {
  width: 80px !important;
  @include flex-box(center,center,$gap-15);
  flex-direction: column;
  li {
    @include flex-box(center,center,$gap-5);
    flex-direction: column;
    cursor: pointer;
    @media (max-width:529px) {
      color: $light-color;
      svg:hover {
        background-color: $dark-color;
      }
    }
    svg {
      font-size: 45px;
      border-radius: $border-r-full;
      padding: $padding-l;
      border: 1px solid;
    }
    img {
      width: 45px;
      height: 45px;
      border-radius: $border-r-full;
      cursor: pointer;
    }
  }
}


.short-v-container {
  scroll-snap-align: start;
  gap: $gap-15;
  position: relative;
  @extend .height;
  margin: 0 auto;
  width: fit-content;
  .short-v-player {
    width: fit-content;
    display: flex;
    align-items: center;
    @extend .height;
    position: relative;
    .short-video {
      position: relative;
      max-width: 100%;
      .short-player {
        @extend .height;
        object-fit: cover;
        width: 100vw !important;
        min-width: 100vw !important;
        max-width: 100vw;
        @media (min-width: 480px) {
          width: 330px !important;
          min-width: 330px !important;
          max-width: 330px;
          border-radius: 10px;
          overflow: hidden;
        }
      }
      h4 {
        position: absolute;
        font-size: $font-size-6;
        bottom: 49px;
        left: 53px;
        text-shadow: 2px 2px 2px #000;
        color: $light-color
      }
    }
    ul.sh-links-comment {
      @extend .shorts-ul;
      @media (max-width: 529px) {
        position: absolute;
        right: 0;
        top: 5px;
        height: 100%;
        justify-content: center;
        li {
          h5 {
            text-shadow: 2px 2px 2px #000;
          }
        }
      }
      &.comment {
        margin-left: -80px;
        z-index: 9999999999999999;
        li {
          color: $light-color !important;
          svg:hover {
            background-color: $dark-color !important;
          }

        }

      }
    }
  }
  .c-overlay {
    @include position(0,0,100%,100%,fixed);
    @include flex-box(flex-start,center);
    svg {
      margin-top: 80px;
      width: 30px;
      height: 30px;
      border-radius: $border-r-full;
      border: 1px solid;
      cursor: pointer;
    }
  }
  .shorts-comments {
    position: absolute;
    top: 9%;
    left: 0;
    width: 100%;
    height: 90%;
    border: 1px solid transparent;
    z-index: 999999999999;
    .comments {
      max-height: 100%;
      height: 100%;
      .wrapper {
        overflow-y: auto;
        max-height: calc(100% - 52px);
        overflow: auto;
        .scroll {
          min-height: fit-content; 
         }
      }
    }
    @media (min-width:992px) {
      left: 100%;
      top: 0;
      height: 98%;
    }
  }
  // .arrow-btn {
  //     position: absolute;
  //     bottom: 40px;
  //     border-radius: 50%;
  //     padding: 5px;
  //     display: flex;

  //     &.left {
  //        left: 3px;
  //        @media (min-width:530px) {
  //            left: -54px;
  //        }
  //     }

  //     &.right {
  //         right: 3px;
  //      }
  // }
}

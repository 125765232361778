@import "./Variabls";

// text colors

@each $key,$val in $text-Colors {
    .t-color-#{$key} {
        color: $val;
    };
    .border-c-#{$key} {
        border-color: $val;
    }

    @if($key == "dark") {
        @for $i from 1 through 10 {
            .t-color-#{$key}-#{$i} {
                color: mix($dark-color, $val, $i * 10);
            };
        };
    }@else {
        @for $i from 1 through 10 {
            .t-color-#{$key}-#{$i} {
                color: mix($light-color, $val, $i * 10);
            };
        };
    };
};

// backgrounds colors

@each $key,$val in $back-colors {
    .back-color-#{$key} {
        background-color: $val;
    };

    @if($key == "dark") {
        @for $i from 1 through 10 {
            .back-color-#{$key}-#{$i} {
                background-color: mix($light-color, $val, $i * 5);
            };
            .back-before-c-#{$key}-#{$i} {
                &::before, &::after {
                    background-color: mix($light-color, $val, $i * 5);
                }
            }
            .back-act-c-#{$key}-#{$i} {
                &.active {
                    background-color: mix($light-color, $val, $i * 5);
                }
            }
            .back-hov-c-#{$key}-#{$i} {
                &:hover {
                    background-color: mix($light-color, $val, $i * 5);
                }
            }
            .border-c-#{$key}-#{$i} {
                border-color: mix($light-color, $val, $i * 5) !important;
            }
        };
        .b-g-t-#{$key} {
            background-color: #001d1b65;
        }

    }@else {
        @for $i from 1 through 10 {
            .back-color-#{$key}-#{$i} {
                background-color: mix($dark-color, $val, $i * 5);
            };
            .back-before-c-#{$key}-#{$i} {
                &::before, &::after {
                    background-color: mix($dark-color, $val, $i * 5);
                }
            }
            .back-act-c-#{$key}-#{$i} {
                &.active {
                    background-color: mix($dark-color, $val, $i * 5);
                }
            }
            .back-hov-c-#{$key}-#{$i} {
                &:hover {
                    background-color: mix($dark-color, $val, $i * 5);
                }
            }
            .border-c-#{$key}-#{$i} {
                border-color: mix($dark-color, $val, $i * 5) !important;
            }
        };
        .b-g-t-#{$key} {
            background-color: #f5fffe65;
        }
    };
};

.link-c {
    color: #3030c5;
}
@import '../../Styles/Variabls';
@import '../../Styles/Mixin';

.video-details {
    .container {
       max-width: 720px;
       @media (min-width: 992px) {
        @include flex-box();
         max-width: 1300px;
          gap: $gap-20;
       }
       .related-video {
         max-width: 100%;
         position: relative;
          @media (min-width: 992px) {
            max-width: 33%;
             flex-basis: 33% !important;
             width: 100%;
         }
       }
    }
}
@import "../../../../Styles/Variabls";
@import "../../../../Styles/Mixin";

.sort-by {
    padding: $padding-10 0;
    margin-bottom: $marging-10;
    ul {
        @include flex-box;
        li {
            border: 1px solid transparent;
            font-size: $font-size-5;
            font-weight: 700;
            padding: $padding-5  $padding-10;
            border-radius: $border-r-5;
            text-transform: capitalize;
            cursor: pointer;
        }
    }
  }
.channel-videos-container {
    .shorts-container {
       display: flex;
       flex-wrap: wrap;
       gap: 5px;
    }
    .videos-playlists {
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(360px,1fr));
        gap: $gap-l;
    }
}
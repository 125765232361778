// colors

$primary-color: #003b6a;
$light-pr-color: #1a6199;
$blue-color: #3683c1;
$light-bl-color: #5e9dcf;
$socnedary-color:#14d814;

$text-color-dark-l : #09300d;
$text-color-dark-m:#012c1f;

$text-color-light-l : #c8f8ee;
$text-color-light-m: darken($text-color-light-l,5);
$back-gr-color-dark:#020a08;
$back-gr-color-light:#baffe5;        
$trans-black: #010716b7;

$logo-color: #1fad66;

$gap-l : 10px;
$gap-m : $gap-l / 2;
$border-radius-l : 5px;
$border-radius-m : $border-radius-l / 2;
$padding-l: 10px;
$padding-m: $padding-l / 2;
$margin-l: 10px;
$margin-m: $margin-l / 2;

$font-size-l: 16px;
$font-size-m: 12px;
$logo-font-size: 20px;

$max-container-width : 1400px;


$font-size-1: 1.6rem;
$font-size-2: 1.4rem;
$font-size-3: 1.2rem;
$font-size-4: 1rem;
$font-size-5: .9rem;
$font-size-6: .7rem;

$padding-5 : 5px;
$padding-10: 10px;
$padding-15: 15px;
$padding-20: 20px;
$padding-25: 25px;
$padding-30: 30px;
$padding-40: 40px;
$padding-50: 50px;

$marging-5 : 5px;
$marging-10: 10px;
$marging-15: 15px;
$marging-20: 20px;
$marging-25: 25px;
$marging-30: 30px;
$marging-40: 40px;
$marging-50: 50px;

$gap-5 : 5px;
$gap-10: 10px;
$gap-15: 15px;
$gap-20: 20px;
$gap-25: 25px;
$gap-30: 30px;
$gap-40: 40px;
$gap-50: 50px;

$border-r-5 : 5px;
$border-r-10: 10px;
$border-r-15: 15px;
$border-r-20: 20px;
$border-r-25: 25px;
$border-r-30: 30px;
$border-r-40: 40px;
$border-r-full: 50%;


$dark-color: #001110;
$light-color: #e5fffc;


$text-Colors : (
    "dark": $light-color,
    "light": $dark-color,
);

$back-colors : (
    "dark": $dark-color,
    "light": $light-color,
);
@import "../../Styles/Variabls";
@import "../../Styles/Mixin";

.playlist-card {
  cursor: pointer;
  @media (min-width: 530px) {
    @include flex-box(flex-start,flex-start,$padding-10)
  }
  margin-bottom: $marging-20;
  .playlist-img {
    flex-basis: 40%;
    position: relative;
    .more-videos {
      position: absolute;
      top: -7px;
      left: 10px;
      width: calc(100% - 20px);
      z-index: -1;
      max-height: 100%;
      overflow: hidden;
      img {
        @media (min-width: 530px) {
          border-radius: $border-r-10;
        }
      }
    }
    .p-img {
      box-shadow: 0px -4px 10px -3px $trans-black;
      border-radius: $border-radius-l;
      img {
        height: 100%;
        @media (min-width: 530px) {
          border-radius: $border-radius-l;
        }
      }
    }
    .count {
      @include flex-box(center, center, $gap-m);
      position: absolute;
      bottom: 10px;
      padding: 1px $padding-m;
      background-color: rgba(0, 0, 0, 0.829);
      color: $text-color-light-l;
      border-radius: $border-radius-m;
    }
    .play-all {
      display: none;
      background-color: rgba(0, 0, 0, 0.651);
      color: $text-color-light-l;
      text-transform: uppercase;
      font-size: $font-size-m;
      font-weight: 700;
      z-index: 9999;
      @media (min-width: 530px) {
        border-radius: $border-radius-l;
      }
    }
  }
  &:hover .play-all {
    @include flex-box(center, center, $gap-m);
  }
  .p-t-box {
    flex-basis: 60%;
    padding: 0 $padding-l;
    flex: 1;
    .p-l-title {
      font-size: $font-size-5;
    }
    .type {
      font-size: $font-size-6;
      text-transform: capitalize;
    }
    .first-tow-video {
      margin-top: $margin-l;
      display: none;
      .details {
        margin-bottom: $marging-10;
        @include flex-box(_, _, 3px);
        font-size: $font-size-6;
      }
    }
  }
}

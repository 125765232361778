@import "../../Styles/Variabls";
@import "../../Styles/Mixin";

.acount-setting {
    position: fixed;
    min-height: 100vh;
    min-width: 100vw;
    top: 0;
    left: 0;
    z-index: 99999999999;
    display: flex;
    justify-content: flex-end;
    .acount-container {
        padding: $padding-20;
        border-radius: $border-r-10;
        width: 300px;
        height: fit-content;
        max-height: calc(100% - 80px);
        margin: $marging-40;
        overflow: hidden;
        .acount-header {
            border-bottom: 1px solid transparent;
            .user-info {
                @include flex-box();
                padding-bottom: $padding-l;
                .user-image {
                    width: 48px;
                    height: 48px;
                    border-radius: $border-r-full;
                }
                .user-name {
                    .name,.channel-handle {
                        font-size: $font-size-6;
                    }
                    a {
                        font-size: $font-size-6;
                        font-weight: 500;
                    }
                }
            }
            }
            
        .acount-body {
            max-height: calc(100vh - 150px);
            overflow-y: scroll;
            padding-bottom: $padding-15;
            .acou-body-cont {
                height: fit-content;
                .google-aco {
                    border-top: 1px solid transparent;
                    padding: $padding-10 0;
                    li {
                        @include flex-box(center);
                        margin-top: $marging-10;
                        cursor: pointer;
                        padding: $padding-10;
                        border-radius: $border-r-10;
                        text-transform: capitalize;
                        font-size: $font-size-m + 2;
                        font-weight: 700;
                    }
                }
            }
        }
    }
    .oppt-menu {
        text-transform: capitalize;
        padding: $padding-10;
        margin: $marging-20;
        border-radius: $border-r-10;
        max-height: fit-content;
        width: 300px;
        .oppt-header {
            @include flex-box(center);
            border-bottom: 1px solid transparent;
            margin-bottom: $marging-10;
            .icon {
                @include flex-box(center,center);
                border-radius: $border-r-full;
                padding: $padding-10;
            }
            .title {
                font-size: $font-size-5
            }
        }
        .menu-oppt {
            .oppt{
                @include flex-box(center,_,$gap-10);
                cursor: pointer;
                padding: $padding-10;
                border-radius: $border-r-10;
                font-size: $font-size-6;
                margin-bottom: $marging-5;
                span {
                    width: 32px;
                    svg {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
@import "../../Styles/Variabls";
@import "../../Styles/Mixin";

.load-more {
  padding: $padding-l;
  text-align: center;
  button {
    font-size: $font-size-5;
    color: $text-color-dark-m;
    background-color: darken($color: $back-gr-color-light, $amount: 1);
    border: 1px solid transparent;
    padding: $padding-m $padding-l + $padding-l;
    border-radius: 50px;
    text-transform:capitalize;
    font-weight: 700;
    &:disabled,
    &:disabled:hover {
      background-color: $logo-color;
      color: $text-color-light-l;
      border-color: darken($color: $logo-color, $amount: 10);
      cursor: wait;
    }
    &:hover {
      background-color: darken($color: $back-gr-color-light, $amount: 5);
      border-color: darken($color: $back-gr-color-light, $amount: 10);
    }
  }
  &.dark {
    button {
      color: $text-color-light-l;
      background-color: lighten($color: $back-gr-color-dark, $amount: 5);
      &:hover {
        background-color: lighten($color: $back-gr-color-dark, $amount: 10);
        border-color: lighten($color: $back-gr-color-dark, $amount: 25);
      }
    }
  }
}


@import '../../Styles/Variabls';

.short-card {
    overflow: hidden;
    cursor: pointer;
    min-width: 210px !important;
    .short-img {
        position: relative;
        max-height: 380px;
        border-radius: $border-r-10;
        min-height: 373px ;
        min-width: 210px !important;
        max-width: 280px;
        overflow: hidden;
        img {
           min-height: 373px ;
        }
        .short-player{
            z-index: 999;
        }
        .short-layout {
            z-index: 9999;
            background-color: transparent;
        }
    }
    .short-desc {

        @media (max-width: 529px) {
            .short-title {
                color: $light-color;
             }
             .sh-viwes {
                color: $light-color;
             }
        }
        .short-title {
           font-size: $font-size-5;
        }
        .sh-viwes {
            font-size: $font-size-6;
        }
    }
    @media (max-width: 530px) {
        position: relative;
        .short-desc {
            position: absolute;
            bottom: 10px;
            left: 10px;
        }
    }
}
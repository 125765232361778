
@import "../../Styles/Variabls";
@import "../../Styles/Mixin";

nav.side-navbar {
    position: fixed;
    top: 0;
    z-index: 9999999999;
    width: 100%;
    .nav-header {
        padding: $padding-15;
        width: 240px;
    }
    .nav-container {
        width: 240px;
        padding: $padding-15;
        padding-top: 0;
        height: 100vh;
        overflow-x: scroll;
        .title {
            text-transform: capitalize;
            margin-top: $marging-10;
            font-size: $font-size-5;
        }
        .nav-icons {
            padding: $padding-10 0;
            border-width: 0;
            border-bottom-width: 1px ;
            border-style: solid;
            a, h3{
                font-size: $font-size-5;
                font-weight: 400;
            }
            a {
                @include flex-box();
                border-radius: $border-r-10;
                padding: $padding-10;
                text-transform: capitalize;
                margin-bottom: $marging-10;
            }
        }
    }
}
@import '../../../../Styles/Variabls';
@import '../../../../Styles/Mixin';

.channel-home{
   .part-container {
      padding-bottom: $padding-30;
      margin-bottom: $marging-30;
      max-width: 100%;
      position: relative; 
      &:not(:last-of-type){
         border-bottom: 1px solid transparent;
      }
      .part-title {
         @include flex-box(center);
         margin: $marging-15 0;
          font-size: $font-size-5;
          font-weight: 500;
          .pl-a {
            @include flex-box(center,flex-start,0);
            font-size: 14px;
            cursor: pointer;
            border-radius: $border-r-20;
            padding: 3px $padding-10;
            border: 1px solid transparent;
            span {
               padding: 0 $padding-10;
            }
            svg {
               padding: 0;
            }
          }
          img {
            width: 30px;
          }
         }
      .sub-title {
         margin-bottom: $marging-15;
         font-size: $font-size-6;
         font-weight: 500;
      }
      .scroll-next,.scroll-prev {
         display: none;
         @media (min-width: 430px) {
            position: absolute;
            @include flex-box(center,center);
            top: calc(50% - 30px);
            width: 30px;
            height: 30px;
            border-radius: $border-r-full;
            padding: $padding-20;
            cursor: pointer;
            z-index: 99999;
            svg {
               font-size: 18px;
            }
            &:hover {
               svg {
                  transform: scale(1.5);
               }
            }
         }
      }
      .scroll-next {
         right: -24px;
      }
      .scroll-prev {
         left: -24px;
      }
      .videos-wraper { 
         overflow-y: auto;
         max-width: 100%;
         .channel-home {
            display: flex;
            flex-wrap: nowrap;
            gap: 10px;
            width: fit-content;
            min-width: 100%;

         }
         .video-card, .playlist-card{
            display: block;
            width: 300px;
            min-width: 280px;
            .playlist-img {
               max-height: 168px;
               .p-img {
                  max-height: 100% !important;
                  overflow: hidden;
               }
            }
         }
      }
      .channel_listing {
         .main-chan-card {
            flex-direction: column !important;
            justify-content: center;
            .card {
               .img-l  {
                  width: 90px !important;
                  height: 90px !important;
               }
            }
            .subscribe  {
               flex-direction: column;
               text-align: center;
               justify-content: center;
            }
            .name {
               text-align: center;
            }
         }
      }
      .members_listing {
         .member-image {
             
            .img {
               min-width: 60px;
               min-height: 60px;
               border-radius: 50%;
            }
         }
      }
   }
}
@import "../../Styles/Variabls";
@import "../../Styles/Mixin";


.comments {
   margin: 0 $marging-10  $marging-10 ;
   border-radius: $border-r-10;
   border: 1px solid transparent;
    &.watch.active {
       @media (max-width: 429px) {
          position: fixed;
          top: 280px;
          max-height: calc(100% - 280px);
          padding-bottom: $padding-l;
          left: 0;
          width: 100%;
          z-index: 999999999;
          margin: 0;  
          border-radius: 0;
       .wrapper {
          height: calc(100vh - 350px);
          overflow-y: auto;
       }
       }
    }
    .comment-head {
       cursor: pointer;
       padding: $padding-15;
       margin-bottom: 0;
       border-bottom: 1px solid transparent;
        .comm-title {
           @include flex-box(center,space-between);
           text-transform: capitalize;
           font-size: $font-size-l;
           .comm-arrows {
             justify-self: flex-end;
             @media (min-width: 992px) {
                display: none;
             }
          }
       }
    }
    .wrapper {
     &.watch.active {
        display: none;
        @media (min-width: 992px) {
           display: unset;
        }
     }
    }
 }
@import '../../Styles/Variabls';
@import '../../Styles/Mixin';

.video {
    padding-top: $padding-10;
    width: 100%;
    @media (min-width: 992px) {
      flex-basis: 67%;
    }
    .channel-card {
      @include flex-box(center,flex-start,$gap-10);
      margin-bottom: $marging-10;
      .ch-img {
        img {
          width: 40px;
          height: 40px;
          border-radius: $border-r-full;
        }
      }
      .ch-info {
        flex: 1;
        @include flex-box(center,space-between,$gap-5);
        .ch-det {
          .ch-name {
             font-size: $font-size-5;
             font-weight: 500;
          }
          .ch-subs-cout {
             font-size: $font-size-6;
             font-weight: 700;
          }
        }
      }

    }
     .video-desc {
       .video-title {
          font-size: $font-size-4;
       }
       .left {
          border-bottom: 1px solid transparent;
          padding: $padding-15 0;
       }
     }
  }

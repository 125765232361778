@import "./Styles/Variabls";
@import "./Styles/Mixin";
@import "./Styles/colors";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: all .4s ease-in-out !important;
  scroll-behavior: smooth;
  text-transform: capitalize;
}
*::-webkit-scrollbar{
  display: none;
}
a {
  text-decoration: none;
  cursor: pointer;
}
li {
  list-style: none;
}
img, picture {
  width: 100%;
  max-width: 100%;
  max-height: 100% !important;
  object-fit: fill;
  background-position: center;
}
input, button, input:focus, button:focus {
  border-color: transparent;
  outline-color: transparent ;
  cursor: pointer;
}
.container {
  margin: 0 auto;
  max-width: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.home-short {
  height: calc(100vh - 80px);
  position: sticky;
  top: 60px;
  display: none;
  @media (min-width: 790px) {
    display: block;
  }
  .link {
      @include flex-box(center,center,0);
      flex-direction: column;
      border-radius: $border-r-10;
      text-transform: capitalize; 
      margin: $marging-10;
      padding: $padding-5;
      min-width: fit-content;
    }
    h3 {
        font-size: $font-size-6;
        min-width: fit-content;
        text-align: center;
        font-weight: 300;
     } 

}

.related-keywords {
    position: sticky;
    padding: $padding-10 ;
    top: 55px;
    z-index: 99999;
    .related-container  {
      overflow: auto;
      .taps {
        display: flex;
        gap: 5px;
        width: fit-content;
        li {
            min-width: fit-content;
            text-transform: capitalize;
            border-radius: $border-r-5;
            padding: $padding-5 $padding-10;
            border: 1px solid transparent;
            cursor: pointer;
            font-size: $font-size-5;
            font-weight: 700;
            border: 1px solid transparent;
        }
    }
    }
  &.video-pla {
    top: 240px;
    @media (min-width: 330px) {
        top: 245px;
        padding-top: $padding-15;
        margin-bottom: 0;
    }
    @media (min-width: 360px) {
      top: 270px;
    }
    @media (min-width: 390px) {
      top: 285px;
    }
    @media (min-width: 430px) {
      top: 50px;
    }
  }
} 

.key-loading {
  @include flex-box(flex-start,center,0);
  z-index: 99999;
  * {
    position: sticky;
    top: 50%;
    left: 50%;
    border-radius: $border-r-full;
    border: 6px solid ;
    height: 60px;
    width: 60px;
    border-color: #818080af transparent transparent transparent;
    animation: key-loading .5s infinite alternate;
  }
}

@keyframes key-loading {
  100% {
    transform: rotate(180deg);
    border-color: #818080af;
  }
}

.absolute {
  position: absolute;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  left: 0;
  top: 0;
}


.video-player {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  margin: 0 auto;
  max-height: 190px;
  z-index: 999999999;
  display: block;
  position: fixed;
  left: 0;
  top: 50px;
@media (min-width: 330px) {
  max-height: 210px;
}
@media (min-width: 360px) {
  max-height: 226px;
}
@media (min-width: 390px) {
  max-height: 245px;
}
@media (min-width: 430px) {
  max-height: 270px;
  position: unset;
  margin: 0 $marging-10;
  top: unset;  
  border-radius: $border-r-10;
  max-width: calc(100% - 20px);
  min-width: calc(100% - 20px);
  border: 1px solid transparent;
  overflow: hidden;
}
@media (min-width: 460px) {
  max-height: 282px;
}
@media (min-width: 490px) {
  max-height: 295px;
}
@media (min-width: 490px) {
  max-height: 320px;
}
@media (min-width: 580px) {
  max-height: 380px;
}
@media (min-width: 670px) {
  max-height: 400px;
}
@media (min-width:992px) {
  min-width: 100%;
  min-height: 400px;
  max-height: 500px;
}
@media (min-width: 1170px) {  
  height: 505px;
  min-height: 505px;

}
}

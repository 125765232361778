@import "../../Styles/Variabls";
@import "../../Styles/Mixin";

.search-recorder {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    @include flex-box (flex-start,center,0);
    z-index: 99999999999;
    padding: $padding-10;
    .recorder-container {
        width: 500px;
        max-width: 100%;
        border-radius: $border-r-10;
        padding: $padding-20;
        .recorder-header {
            .cancel {
                margin-bottom: $marging-10;
                span {
                    cursor: pointer;
                    svg {
                        font-size: 28px;
                    }
                }
            }
            .listing {
                text-transform: capitalize;
            }
        }
        .recorder-box {
            @include flex-box(center,center);
            height: 300px;
            .mike-container {
                width: 60px;
                height: 60px;
                border: 2px solid transparent;
                border-radius: $border-r-full;
                @include flex-box(center,center);
                .rec-mike {
                    @include flex-box(center,center);
                    width: 60px;
                    padding: $padding-5;
                    border-radius: $border-r-full;
                    cursor: pointer;
                        svg {
                            font-size: 50px;
                        }
                    
                }
                &.active {
                    animation: recording .5s infinite alternate ease-in-out;
                    .rec-mike {
                        color: $light-color !important;
                        background-color: red;
                    }
                }

            }
        }
        .recorder-input-box {
            @include flex-box(center,center,0);
            .recorder-inp{
                background-color: transparent;
                border-radius: $border-r-5;
                border: 1px solid transparent;
                padding: $padding-5 $padding-10;
                font-size: $font-size-l;
            }
        }
    }
}

@keyframes recording {
    100% {
        width:120px;
        height: 120px;
    }
}

@import '../../Styles/Variabls';
@import '../../Styles/Mixin';

.chanel-details {
    display: flex;
    gap: 10px;
    width: 100%;
    .container {
        padding: $padding-15;
        max-width: 1120px;
        width: 100%;
        margin: 0 auto;
        flex: 1;
        @media (min-width: 790px) {
          width: calc(100% - 130px);
        }
        .banner {
            img {
                border-radius: $border-r-10;
            }
        }
        .ch-card {
            @include flex-box(center,flex-start,$gap-15);
            margin: $marging-15 0;
            .top {
                @include flex-box(center,flex-start,$gap-10);
                .ch-card-img-2 {
                    width: 80px;
                    height: 80px;
                    border-radius: $border-r-full;
                    @media (min-width:767px) {
                        display: none;
                    }
                }
                .ch-t {
                    font-size: $font-size-3;
                    margin-bottom: $marging-5;
                    max-height: 32px;
                    overflow: hidden;
                }
                .m-h {
                    max-height: 21px;
                    overflow: hidden;
                }

            }
            .ch-card-img {
                display: none;
                @media (min-width:767px) {
                    border-radius: $border-r-full;
                    display: block;
                    width: 120px;
                    height: 120px;
                }
                @media (min-width:992px) {
                    width: 160px;
                    height: 160px;
                }
            }
            .ch-info {
                .flix {
                    @include flex-box(center,flex-start,$gap-5);
                    font-weight: 500;
                    svg {
                        font-size: $font-size-5;
                    }
                }
                .ch-desc {
                    margin: $marging-5 0;
                    cursor: pointer;
                }
                .ch-card-links {
                    margin-bottom: $marging-10;
                }
                .subs-b {
                    min-width: 100%;
                    @media (min-width:580px) {
                        min-width: unset;
                    }
                }
            }
        }
         .channel-content {
            max-width: 1170px;
            margin: 0 auto;
            .playlist-container {
                display: grid;
                grid-template-columns: repeat(auto-fill,minmax(280px,1fr));
                gap: 10px;
                padding: 20px 10px;
            }
         }
    }
}
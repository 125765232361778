@import "../../Styles/Variabls";
@import "../../Styles/Mixin";

header.main-header {
    position: sticky;
    top: 0;
    z-index: 999999999;
    .container {
        @include flex-box(center,space-between,0);
        height: 60px;
        position: relative;
        max-width: $max-container-width;
        padding: 0 $padding-l;
        z-index: 999999999;
    }
    .logo {
        width: unset;
    }
}

.prof-create {
    @include flex-box(center,center);
    .icon {
        @include flex-box(center,center,$gap-10);
        padding: $padding-10;
        border-radius: $border-r-full;
        cursor: pointer;
        svg {
            font-size: 26px;
        }
    }
    .user-img  {
        cursor: pointer;
        svg {
            font-size: 28px;
        }
    }
}
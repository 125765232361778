@import "../../Styles/Variabls";
@import "../../Styles/Mixin";

.subs-btn {
  position: relative;
  .subs-b {
    @include flex-box(center, center, $gap-5);
    font-size: $font-size-5;
    padding: $padding-5 + 3 $padding-15;
    border-radius: $border-r-20;
    border: 1px solid transparent;
    font-weight: 700;
    svg {
      font-size: $font-size-4;
    }
  }
  .notif-selec {
    border: 1px solid transparent;
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    width: fit-content;
    height: fit-content;
    border-radius: $border-r-10;
    z-index: 999999999999999999999999;
    .not-opp {
      padding: $padding-10 0;
      li {
        @include flex-box(center, flex-start, $gap-5);
        padding: $padding-5 $padding-15;
        cursor: pointer;
      }
    }
  }
  &.subscribed:hover {
    .notif-selec {
      display: block;
    }
  }
}

@import "../../Styles/Variabls";
@import "../../Styles/Mixin";

.video-card {
  border-radius: $border-r-5;
  transition: all 0.5s ease-in-out;
  margin-bottom: $marging-20;
  @media (min-width: 530px) {
    @include flex-box(flex-start,flex-start,$padding-10)
  }
  .video-img {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    flex-basis: 40%;
    @media (min-width: 530px) {
      border-radius: $border-r-5;
    }
    span.nav-watch {
      background-color: transparent;
      z-index: 9999;
    }
    img {
      width: 100%;
    }
    span {
      position: absolute;
      bottom: 8px;
      padding: 0px 5px 2px;
      background-color: $trans-black;
      color: $light-color;
      border-radius: $border-r-5;
      font-size: $font-size-6;
      font-weight: 500;
      &.live {
        background-color: $logo-color;
        padding: 0px $padding-m;
        div {
          display: flex;
          gap: $gap-m;
          svg {
            font-size: 20px;
          }
        }
      }
    }
  }
  .content {
    padding: $padding-5;
    flex: 1;
    overflow: hidden;
    flex-basis: 60%;
    .wrapper {
      @include flex-box(flex-start);
      a.img {
        @include flex-box();
        min-width: fit-content;
        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
        @media (min-width: 530px) {
          display: none;;
        }
      }
      .titles {
        a {
          .chanel-title {
            @include flex-box();
            font-size: $font-size-6;
        }
          .video-title {
              font-size: $font-size-5;
              font-weight: 700;
          }
        }
        .video-desc {
          display: none;
          font-size: $font-size-6;
        }
      }
    }
    .stats {
      @include flex-box();
      .moment {
        @include flex-box(_, _, $gap-5);
        font-size: $font-size-6;
        svg {
          font-size: 17px;
        }
      }
    }
  }
}

@import "../../Styles/Variabls";
@import "../../Styles/Mixin";

.error {
  @include flex-box(center, center, 20px);
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  height: calc(100vh - 70px);
  padding: $padding-l;
  h3 {
    font-size: $font-size-l;
    font-weight: bold;
    color: $text-color-dark-m;
    text-align: center;
  }
  button {
    padding: 0px $padding-l;
    font-size: $font-size-l;
    font-weight: 900;
    background-color: darken($color: $back-gr-color-light, $amount: 10);
    color: $text-color-dark-l;
    text-transform: capitalize;
    border-radius: $border-radius-m;
    &:hover {
      background-color: darken($color: $back-gr-color-light, $amount: 20);
    }
  }
  &.dark {
    h3 {
      color: $text-color-light-m;
    }
    button {
      color: $text-color-light-l;
      background-color: lighten($color: $back-gr-color-dark, $amount: 10);
      &:hover {
        background-color: lighten($color: $back-gr-color-dark, $amount: 20);
      }
    }
  }
}

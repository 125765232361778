@import '../../Styles/Variabls';
@import '../../Styles/Mixin';
main.main-home{
    @include flex-box();
    max-width: 100vw;
    .container {
        position: relative;
        max-width: 100%;
        flex: 1;
        @media (min-width: 790px) {
            max-width: calc(100vw - 140px);
        } 
        .related-videos {
            @include grid-box(340px,1fr,$gap-20);
            .videos-container {
                @include grid-box(340px,1fr,$gap-20);
            }
            .video-card  {
                @media (min-width:530px) {
                    display: block !important;
                    .img {
                        display: block !important;
                    }
                }
            }
        }
    }
}
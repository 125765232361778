@import "../../Styles/Variabls";
@import "../../Styles/Mixin";
.related-videos {
    @media (min-width: 530px) {
        padding: $padding-20;
    }
    .listing {
        border-bottom: 1px solid transparent;
        padding-bottom: $padding-20;
        margin-bottom: $marging-40;
        @media (min-width: 620px) {
            grid-column-start: 1;
            grid-column-end: 3;
        }
        @media (min-width: 920px) {
            grid-column-end: 4;
        }
        h5.listing-title {
            font-size: $font-size-4;
            margin: $marging-15 0;
            @include flex-box(center);
            img {
                width: 30px;
            }
        }
        .shorts-container {
            @include flex-box;
            overflow: auto;
            margin-bottom: $marging-15
        }
    } 
}


.query-container {
    @include flex-box;
    overflow: auto;
    width: 100%;
    max-width: 100%;
    .query {
    width: 156px;
    min-width: 156px;
    border-radius: $border-r-10;
    overflow: hidden;
    margin-bottom: $marging-15;
    color: $text-color-dark-l;
    img {
        min-width: 156px;
    }
    h5 {
        margin: $margin-l 0;
        text-align: center;
    }
    }
}
@import '../../Styles/Variabls';
@import '../../Styles/Mixin';

.channel-nav {
    position: sticky;
    top: 60px;
    width: 100%;
    overflow-y: auto;
    z-index: 99999;
    border-bottom: 1px solid transparent;
    margin-bottom: $marging-10;
    overflow: auto;
    .nav {
        @include flex-box;
        padding-bottom: $marging-10;
        width: fit-content;
        a,.about {
            @include flex-box(center,center);
                min-width: fit-content;
                 padding: $padding-5 $padding-10;
                 font-size: $font-size-5;
                 text-transform: capitalize;
                 font-weight: 500;
                 cursor: pointer;
                 position: relative;
                 &::before {
                     position: absolute;
                     content: '';
                     left: 0;
                     bottom: -5px;
                     height: 2px;
                     border-radius: $border-r-10;
                     transition: all  0.3s ease-in-out;
                 }
                 &:hover {
                     &::before {
                         width: 100%;
                         filter: opacity(60%);
                     }
                 }
                 &.active {
                    filter: opacity(60%);
                     &::before {
                         width: 100%;
                     }
                 }
        }
        
    }
 }